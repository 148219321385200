// Función para ajustar la posición del menú desplegable
const adjustDropdownPosition = (dropdown) => {
    const parent = dropdown;
    const childMenu = dropdown.querySelector('ul');
    if (parent && childMenu) {
        const overflow = parent.offsetLeft + parent.offsetWidth + childMenu.offsetWidth > window.innerWidth;
        childMenu.style.right = overflow ? "0" : "auto";
        childMenu.style.left = overflow ? "auto" : "0";
    }
};

const handleMouseEvents = (event) => {
    const item = event.currentTarget;
    if (window.innerWidth > 950) {
        item.classList.toggle('active', event.type === 'mouseover');
        adjustDropdownPosition(item);
    } else {
        item.classList.remove('active');
    }
};

const initDesktopMenu = () => {
    const navigationItems = document.querySelectorAll('.has-submenu');
    navigationItems.forEach(item => {
        item.removeEventListener('mouseover', handleMouseEvents);
        item.removeEventListener('mouseout', handleMouseEvents);

        item.addEventListener('mouseover', handleMouseEvents);
        item.addEventListener('mouseout', handleMouseEvents);
        adjustDropdownPosition(item);
    });
};

initDesktopMenu();

window.addEventListener('resize', initDesktopMenu);
// required
const megaMenuList = document.querySelectorAll('.sub_menu_3');
megaMenuList.forEach(megaMenu => {
    const parentElement = megaMenu.parentElement.parentElement.parentElement;
    // Realizar acciones con el elemento padre
    parentElement.classList.add('mega_menus');
});

///************** SCRIPT MOBILE ************* */

const initMobileMenu = () => {
    const contentMenu = document.querySelector('#__menu_content');
    const body = document.querySelector('body');
    const btnMobile = document.getElementById('__mobile'); // Eliminamos el '#' ya que getElementById no lo necesita

    // Función para cerrar el menú cuando se haga clic en cualquier lugar de la página
    document.addEventListener('click', (event) => {
        if (!contentMenu.contains(event.target) && !btnMobile.contains(event.target)) {
            let btnicons = btnMobile.querySelector('i');
            btnicons.classList.remove('bi-x-lg');
            btnicons.classList.add('bi-list');
            contentMenu.classList.remove('show_menu_mobile');
        }
    });

    // Función para manejar el clic en el botón móvil
    btnMobile.addEventListener('click', () => {
        let btnicons = btnMobile.querySelector('i');
        btnicons.classList.toggle('bi-x-lg');
        btnicons.classList.toggle('bi-list');
        contentMenu.classList.toggle('show_menu_mobile');
    });
//    acordion script menus
    const hasSubMenuSelector = document.querySelectorAll('.has-submenu');

    hasSubMenuSelector.forEach(item => {
        item.addEventListener('click', async (event) => {
            if (event.target.parentElement.classList.contains('has-submenu') || event.target.classList.contains('has-submenu') || event.target.parentElement.parentElement.classList.contains('has-submenu')) {
                event.preventDefault();
            }
            const isOpen = item.classList.contains('togle_menu');
            await hasSubMenuSelector.forEach(element => {
                element.classList.remove('togle_menu');
                element.style.maxHeight = null;
            });

            if (!isOpen) {
                item.classList.add('togle_menu');
                item.style.maxHeight = item.scrollHeight + 'px';
            }
        });
    });

};

if (window.innerWidth < 950) {
    initMobileMenu();
}

//  ====== chat whatsapp scrip ====== 
const pearcard = document.querySelector('.pearchat-card');
const pearopenchat = document.querySelector('.pearchat-btn-whatsapp');
const pearclose = document.querySelector('.btn-pear-close');

function togglePearCard() {
  pearcard.classList.toggle("show");
}

function closePearCard(e) {
  if (!pearcard.contains(e.target) && e.target !== pearopenchat) {
    pearcard.classList.remove("show");
  }
}

if (pearopenchat) {
  pearopenchat.addEventListener('click', togglePearCard);
}

if (pearclose) {
  pearclose.addEventListener('click', togglePearCard);
}
// document.addEventListener('click', closePearCard);
// Cerrar el chat cuando se presiona "Esc"
document.addEventListener('keydown', function (e) {
  if (e.key === 'Escape' && pearcard.classList.contains('show')) {
    togglePearCard();
  }
});

//  ====== SCROLL TO TOP SCRIPT ====== 
var scrollToTopBtn = document.querySelector(".scrollToTopBtn")
var rootElement = document.documentElement

function handleScroll() {
  // Do something on scroll - 0.15 is the percentage the page has to scroll before the button appears
  // This can be changed - experiment
  var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
  if ((rootElement.scrollTop / scrollTotal ) > 0.15) {
    // Show button
    scrollToTopBtn.classList.add("showBtn")
  } else {
    // Hide button
    scrollToTopBtn.classList.remove("showBtn")
  }
}

function scrollToTop() {
  // Scroll to top logic
  rootElement.scrollTo({
    top: 0,
    behavior: "smooth"
  })
}
scrollToTopBtn.addEventListener("click", scrollToTop)
document.addEventListener("scroll", handleScroll)
